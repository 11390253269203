export function validateText(text) {
  // remove line breaks and carriage returns
  text = text ? text.replace(/[\n\r]/g, "") : null;
  return text !== null && text !== undefined && text !== "";
}

export function validateEmail(email) {
  if (validateText(email)) {
    const reg = /^\w+([-]|[+]|[.]?\w+)*@\w+([-]?\w+)*(\.\w{2,10})+$/;
    return reg.test(email);
  }
  return false;
}

export function humanizeString(string) {
  const blacklistProperties = ["SD"];
  if (blacklistProperties.indexOf(string) > -1) {
    return string;
  }

  const camelMatch = /([A-Z])/g;
  const underscoreMatch = /_/g;

  const camelCaseToSpaces = string.replace(camelMatch, " $1");
  const underscoresToSpaces = camelCaseToSpaces.replace(underscoreMatch, " ");
  const caseCorrected =
    underscoresToSpaces.charAt(0).toUpperCase() +
    underscoresToSpaces.slice(1).toLowerCase();

  return caseCorrected;
}

export function kebabCaseString(string) {
  return string.trim().replace(/\s/g, "-").toLowerCase();
}

export function urlSerializeParams(object, prefix) {
  const str = [];
  for (const p in object) {
    if (object.hasOwnProperty(p)) {
      const k = prefix ? prefix + "[" + p + "]" : p,
        v = object[p];
      if (v !== null && typeof v === "object") {
        str.push(urlSerializeParams(v, k));
      } else {
        str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
  }
  return str.join("&");
}

export const getFormattedValue = (value) => {
  if (!value) {
    return "";
  }
  value = value.replaceAll("_", " ");
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};
